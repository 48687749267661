@import '@styles/variables';
.app_container {
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  background-color: $backgroundApp;
}

.scene_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
}

.scene_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: inherit;
  flex: 1;
}
