@import '@styles/variables';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $backgroundApp;
  padding: 10px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

.logo_wrapper {
  width: 300px;
  height: 170px;
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
}

.content_container {
  overflow: hidden;
  padding: 25px;
  box-sizing: border-box;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border-radius: 4px;
  max-width: 600px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);

  @media (min-width: 1024px) {
    padding: 60px;
  }

  @media (min-width: 768px) {
    padding: 40px;
  }
}

.error_message {
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
}
