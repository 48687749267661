@import '../components/Table/styles/filtration.scss';
@import './variables.scss';
/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v34-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src:
    url('../fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
  src:
    url('../fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v34-latin-800.eot'); /* IE9 Compat Modes */
  src:
    url('../fonts/open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/open-sans-v34-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

*::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

*::-webkit-scrollbar-track {
  background: inherit;
  border-radius: 8px;
  box-shadow: inset 0 0 6px #f8f8f8;
  -webkit-box-shadow: inset 0 0 6px #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  border: $scrollBar;
  border-radius: 8px;
  background-color: $scrollBar;
}

*::-webkit-scrollbar-corner {
  background: inherit;
}

// fix marker z-index
.google-map-markers-overlay {
  div:has(> svg.first) {
    z-index: 9999 !important;
  }
}
