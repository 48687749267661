.filt_button_transition-enter {
  transform: translateX(-100%);
  opacity: 0;
  transition: max-width 0.1s cubic-bezier(0.99, -0.02, 1, 0.49), opacity 0.15s linear,
    transform 0.15s ease-out;
}
.filt_button_transition-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: max-width 0.1s cubic-bezier(0.99, -0.02, 1, 0.49), opacity 0.15s linear,
    transform 0.15s ease-out;
}

.filt_button_transition-exit {
  opacity: 1;
  transform: translateX(0px);
  transition: max-width 0.1s cubic-bezier(0.99, -0.02, 1, 0.49), opacity 0.15s linear,
    transform 0.15s ease-in;
}
.filt_button_transition-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: max-width 0.1s cubic-bezier(0.99, -0.02, 1, 0.49), opacity 0.15s linear,
    transform 0.15s ease-in;
}
