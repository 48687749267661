//main colors
$backgroundApp: #f8f8f8;
$darkBlue: #102334;
$lightBlue: #4c8aa3;
$black: #101010;
$white: #ffffff;
$red: #c12326;
$green: #2b8f47;
$scrollBar: #b6bcc3;
$modalBackground: #e0e0e0;
