@import '@styles/variables';
.container {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: $lightBlue;
  padding: 0 15px;
  box-sizing: border-box;

  cursor: pointer;
  &:hover {
    background-color: red;
    transition: all 0.3s;
  }
}

.item_container {
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #dce9ef;
  }
}

.icon_container {
  display: flex;
  padding: 0 15px;
}

.drawer_body {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.icon {
  color: green;
  &_active {
    color: $lightBlue;
  }

  &_inActive {
    color: #bfbfbf;
  }
}

.title {
  &_active {
    font-weight: 600;
  }
}
