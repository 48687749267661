.tools_container {
  display: flex;
  flex: 1;
  height: inherit;
  justify-content: space-between;
}

.left_tools,
.right_tools {
  height: inherit;
  display: flex;
  align-items: center;
}

.right_tools {
  padding-right: 2px;
  min-width: 200px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.left_tools {
  padding: 0 20px;
  flex: 1;
}

.logo_wrapper {
  width: 120px;
  overflow: hidden;
}
.image {
  width: inherit;
}

.menu_container {
  padding: 10px 14px;
}
